body {
  height: 100%;
  background: #ffe600;
  display: flex;
  justify-content: center;
  align-items: center;

  [role='main'] {
    display: block;
  }
}
